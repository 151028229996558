/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Malachi's Portfolio",
  description:
    "A passion for helping people achieve their goals through technology.",
  og: {
    title: "Malachi Clark's Portfolio",
    type: "website",
    url: "https://nb.malachi.cc/",
  },
};

//Home Page
const greeting = {
  title: "Malachi Clark",
  logo_name: "Malachi Clark",
  nickname: "",
  subTitle:
    "I have over 25 years of history working professionally with people and computers, and since age 5, it has been my life’s ambition, hobby, work, and fun. Throughout all of it, I have maintained a passion for helping people achieve their goals through technology.",
  coverLetterLink:
    "https://drive.google.com/file/d/1s7devgWTLuUmZta4lmGvtQjVPwPVWxQY/view?usp=sharing",
  resumeLink:
    "https://drive.google.com/file/d/1o-zQt5k7NLVecrq0OhOpEKjPliCoE3Us/view?usp=sharing",
  portfolio_repository: "",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"
  // {
  //   name: "Github",
  //   link: "https://github.com/ashutosh1919",
  //   fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
  //   backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  // },
  // {
  //   name: "LinkedIn",
  //   link: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  //   fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
  //   backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  // },
  // {
  //   name: "YouTube",
  //   link: "https://www.youtube.com/channel/UC_amoXmmxSY9KusoDczDTXQ",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  // {
  //   name: "Gmail",
  //   link: "mailto:ashutoshhathidara98@gmail.com",
  //   fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
  //   backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  // },
  // {
  //   name: "Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
  //   backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/layman_brother/",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
];

const skills = {
  data: [
    {
      title: "Management / CTO",
      fileName: "DataScienceImg",
      skills: [
        "• Manage diverse teams of network engineers and programmers for over 25 years",
        "• Interface with other C-level executives and stakeholders to relay critical information",
        "• Hire exceptional tech talent to achieve goals",
        "• Budget analysis and creation for a wide variety of budgets",
        "• IT audits",
        "• Project management",
      ],
      softwareSkills: [
        // {
        //   skillName: "Tensorflow",
        //   fontAwesomeClassname: "logos-tensorflow",
        //   style: {
        //     backgroundColor: "transparent",
        //   },
        // },
        // {
        //   skillName: "Keras",
        //   fontAwesomeClassname: "simple-icons:keras",
        //   style: {
        //     backgroundColor: "white",
        //     color: "#D00000",
        //   },
        // },
        // {
        //   skillName: "PyTorch",
        //   fontAwesomeClassname: "logos-pytorch",
        //   style: {
        //     backgroundColor: "transparent",
        //   },
        // },
        // {
        //   skillName: "Python",
        //   fontAwesomeClassname: "ion-logo-python",
        //   style: {
        //     backgroundColor: "transparent",
        //     color: "#3776AB",
        //   },
        // },
      ],
    },
    {
      title: "Information Technology",
      fileName: "ProgrammingImg",
      skills: [
        "• Network analysis and planning",
        "• Configuration and management of network equipment",
        "• Enterprise level high availability, backup, and failover",
        "• Drastic cost savings through automation",
        "• Helpdesk, diagnostics, and troubleshooting",
      ],
      softwareSkills: [
        {
          skillName: "Windows",
          fontAwesomeClassname: "simple-icons:windows",
          style: {
            backgroundColor: "transparent",
            color: "#06a7cb",
          },
        },
        {
          skillName: "Mac",
          fontAwesomeClassname: "simple-icons:apple",
          style: {
            backgroundColor: "transparent",
            color: "#696969",
          },
        },
        {
          skillName: "Linux",
          fontAwesomeClassname: "simple-icons:linux",
          style: {
            backgroundColor: "transparent",
            color: "#000000",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "VMWare",
          fontAwesomeClassname: "simple-icons:vmware",
          style: {
            color: "#636363",
          },
        },
        {
          skillName: "Ansible",
          fontAwesomeClassname: "simple-icons:ansible",
          style: {
            backgroundColor: "transparent",
            color: "#000000",
          },
        },
        {
          skillName: "TerraForm",
          fontAwesomeClassname: "simple-icons:terraform",
          style: {
            backgroundColor: "transparent",
            color: "#682092",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "• Extensive experience working on multiple cloud platforms",
        "• Hosting and maintaining applications and databases on virtual machine instances",
        "• High availability through failover and load balancing",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Microsoft SQL",
          fontAwesomeClassname: "simple-icons:microsoftsqlserver",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "• Developed in 18 programming languages",
        "• Building responsive websites",
        "• Developing mobile applications using Flutter and Ionic",
        "• Create application backends in Python, PHP, and more in wide variety of languages",
        "• Experience in many different frameworks and methodologies",
        "• Proficiency in all major databases, including MSSQL, MySQL, PostgreSQL, and more",
        "• Automation and scripting",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "TypeScript",
          fontAwesomeClassname: "simple-icons:typescript",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "logos:python",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            color: "#206718",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    // {
    //   title: "UI/UX Design",
    //   fileName: "DesignImg",
    //   skills: [
    //     "⚡ Designing highly attractive user interface for mobile and web applications",
    //     "⚡ Customizing logo designs and building logos from scratch",
    //     "⚡ Creating the flow of application functionalities to optimize user experience",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Adobe XD",
    //       fontAwesomeClassname: "simple-icons:adobexd",
    //       style: {
    //         color: "#FF2BC2",
    //       },
    //     },
    //     {
    //       skillName: "Figma",
    //       fontAwesomeClassname: "simple-icons:figma",
    //       style: {
    //         color: "#F24E1E",
    //       },
    //     },
    //     {
    //       skillName: "Adobe Illustrator",
    //       fontAwesomeClassname: "simple-icons:adobeillustrator",
    //       style: {
    //         color: "#FF7C00",
    //       },
    //     },
    //     {
    //       skillName: "Inkscape",
    //       fontAwesomeClassname: "simple-icons:inkscape",
    //       style: {
    //         color: "#000000",
    //       },
    //     },
    //   ],
    // },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "https://www.hackerrank.com/layman_brother",
    // },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/ashutosh_1919",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/layman_brother",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Orange Coast College",
      subtitle: "Computer Science",
      logo_path: "occ_logo.png",
      alt_name: "Orange Coast College",
      duration: "1997",
      descriptions: ["• Majored in Computer Science"],
      website_link: "https://orangecoastcollege.edu/",
    },
    {
      title: "Orange Coast College",
      subtitle: "EMT Program",
      logo_path: "occ_logo.png",
      alt_name: "Orange Coast College",
      duration: "2011",
      descriptions: ["• Perfect score in training"],
      website_link: "https://orangecoastcollege.edu/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Costa Mesa CERT",
      subtitle: "2011-Current",
      logo_path: "cert.png",
      certificate_link: "",
      alt_name: "Costa Mesa CERT",
      color_code: "#FFFFFF",
    },
    {
      title: "National Registry of EMTs",
      subtitle: "2011-2014",
      logo_path: "nremt.png",
      certificate_link: "",
      alt_name: "NREMT",
      color_code: "#FFFFFF",
    },
    {
      title: "Orange County EMS Accreditation",
      subtitle: "2011-2014",
      logo_path: "ocems.png",
      certificate_link: "",
      alt_name: "ocems",
      color_code: "#FFFFFF",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work and Volunteering",
  description:
    "I have worked with a variety of offices, from startups to large companies in IT, in software development and management. I have written code in 18 different programming languages, worked with almost every kind of modern computer system, hosted a radio show about computers and the internet, created patent pending applications, and worked for think tanks. I have owned and managed a number of technology companies.",
  header_image_path: "resume.svg",
  sections: [
    {
      title: "Work History",
      experiences: [
        {
          title: "Owner / President",
          company: "Techglia, Inc",
          company_url: "http://techglia.com/",
          logo_path: "Techglia_Logo.png",
          duration: "Oct 2002 - Present",
          location: "Costa Mesa, CA",
          descriptions: [
            "Manage a team of 24-hour on call IT technicians providing all IT services to a wide variety of clientele",
            "Set up and manage cloud-based infrastructure for many companies (AWS and Google Cloud)",
            "Manage all DevOps and SysAdmin activities internally and for clients",
            "Personally responsible for thousands of computers, servers, and devices",
            "Create and manage budgets internally and for clients",
            "Provide security services and audits",
            "Create, manage and test failover, disaster response, and redundancy solutions",
            "Set up and manage hardware and software servers, including real-time monitoring and proactive maintenance",
            "Set up and manage a wide variety of networks",
            "Support requests from hundreds of end users both onsite and remotely via email and phone",
            "Chief programmer and maintainer of all custom programming projects",
            "Use many programming technologies including Python, PHP, Dart, TypeScript, JavaScript, Django, Angular, and Flutter",
            "Develop and maintain e-commerce solutions (WooCommerce, Magento, and custom)",
            "Manage a geographically separated team of programmers",
          ],
          color: "#0A2472",
        },
        {
          title: "Director of Technology / CTO",
          company: "Stick-e-Media",
          company_url: "",
          logo_path: "stick-e-media.png",
          duration: "Jun 2001 - Oct 2002",
          location: "Los Angeles, CA",
          descriptions: [
            "Made all technology related decisions for the company",
            "Managed and hired all technical employees",
            "Administered all server and networking hardware",
            "Designed and managed all project implementations and budgets",
            "Developed patents (pending) for software projects",
            "Chief engineer and programmer of all projects (PHP, ColdFusion, VB, C#)",
          ],
          color: "#0A2472",
        },
        {
          title: "Technology Manager / Chief Programmer / Full Stack Developer",
          company: "Clickpath Design  /  Stirstick Interactive",
          company_url: "",
          logo_path: "blank.png",
          duration: "Feb 2000 – Jun 2001",
          location: "Newport Beach, CA",
          descriptions: [
            "Decided on all company technology choices",
            "Managed employee workflow and all client interaction / workflow",
            "Oversaw all technology projects",
            "Designed and managed all project implementations",
            "Chief programmer of all web and programming projects",
          ],
          color: "#0A2472",
        },
        {
          title: "Co-Founder / Chief Programmer / Full Stack Developer",
          company: "Unbounded Solutions",
          company_url: "",
          logo_path: "unbounded_logo.png",
          duration: "Feb 1998 – Feb 2000",
          location: "Fairfield, IA",
          descriptions: [
            "Formed company and was active in all aspects of business management",
            "Managed all employees",
            "Server administration of hosting, web, email, ftp, and streaming media servers",
            "Supervised all projects",
            "Chief programmer of all major web projects",
          ],
          color: "#0A2472",
        },
        {
          title: "Radio Engineer / On-Air Personality",
          company: "Internet Solutions Radio",
          company_url: "",
          logo_path: "kmcd.png",
          duration: "Feb 1998 – Feb 2000",
          location: "Fairfield, IA",
          descriptions: [
            "On-air personality for a 2 hour weekly radio show where live questions were answered on air concerning the internet and computers",
            "Managed streaming media servers for simultaneous web broadcast",
          ],
          color: "#0A2472",
        },
        {
          title:
            "Network Engineer / Senior Web Programmer / Interface Engineer",
          company: "Human Factors International",
          company_url: "",
          logo_path: "hfi.png",
          duration: "Sep 1997 – Feb 1998",
          location: "Fairfield, IA",
          descriptions: [
            "Managed team of repair technicians / help desk",
            "Supervised network administration for 50+ users, both desktop and mobile (with remote access)",
            "Maintained backups of all servers, both in-house and remote",
            "Deployed machine installations, including troubleshooting of Windows/Mac systems",
            "Set up and maintained web servers, and developed back-end programming, including usability testing and ergonomics",
            "Programmed and implemented software interface designs",
          ],
          color: "#0A2472",
        },
        {
          title: "Senior Web Engineer / Full Stack Developer",
          company: "Planet Access",
          company_url: "",
          logo_path: "planetaccesslogo.jpg",
          duration: "Jan 1997 – Sep 1997",
          location: "Irvine, CA",
          descriptions: [
            "Set up and administered web servers with hundreds of clients",
            "Developed intranets for multiple Fortune 100 companies",
            "Designed an internet presence for multiple Fortune 500 companies",
            "Created back-end programming for both our own intranet and multiple search engines for differing clients",
          ],
          color: "#0A2472",
        },
      ],
    },
    // {
    //   title: "Internships",
    //   experiences: [
    //     {
    //       title: "Data Science Research Intern",
    //       company: "Delhivery Pvt. Ltd.",
    //       company_url: "https://www.delhivery.com/",
    //       logo_path: "delhivery_logo.png",
    //       duration: "May 2019 - Sept 2019",
    //       location: "Gurgaon, Haryana",
    //       description:
    //         "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
    //       color: "#ee3c26",
    //     },
    //     {
    //       title: "Data Science Intern",
    //       company: "Intel Indexer LLC",
    //       company_url:
    //         "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
    //       logo_path: "intel_logo.jpg",
    //       duration: "Nov 2018 - Dec 2018",
    //       location: "Work From Home",
    //       description:
    //         "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
    //       color: "#0071C5",
    //     },
    //   ],
    // },
    {
      title: "Speaking and Publication",
      experiences: [
        {
          title: '"Lunch and Learn" Presentations',
          company: "Techglia, Inc.",
          company_url: "",
          logo_path: "Techglia_Logo.png",
          duration: "2003-Current",
          location: "Orange County, CA",
          descriptions: [
            "Regular presentations to various clients on security training topics for their employees, usually given to the whole company at once.",
          ],
          color: "#0A2472",
        },
        {
          title: "Quarterly Keynote",
          company: "BNI",
          company_url: "",
          logo_path: "BNI_Logo.jpg",
          duration: "2014-Current",
          location: "Lake Forest, CA",
          descriptions: [
            "Quarterly keynote presentation centered on computers, security, and the internet.",
            "Built highly interactive presentations involving on-screen displays coupled with mobile app integrations.",
          ],
          color: "#0A2472",
        },
        {
          title: "Annual Keynote",
          company: "Philanthropic Educational Organization",
          company_url: "",
          logo_path: "peo.png",
          duration: "2014-Current",
          location: "Orange County, CA",
          descriptions: [
            "Annual keynote presentation on a technology based topic chosen by the members.  Topics such as security and online safety, Bitcoin, and digital addiction.",
          ],
          color: "#0A2472",
        },
        {
          title: "Technology Keynotes",
          company: "Rotary International",
          company_url: "",
          logo_path: "rotary.jpg",
          duration: "2014-Current",
          location: "Orange County, CA",
          descriptions: [
            "Semi regular keynote presentations on technology related topics such as digital life and identity theft.",
          ],
          color: "#0A2472",
        },
        {
          title: "Contributing Writer",
          company: "HB Magazine",
          company_url: "",
          logo_path: "hbmag.png",
          duration: "2010",
          location: "Huntington Beach, CA",
          descriptions: [
            "Contributed articles to HB Magazine relating to online backup, security, and choosing the right IT for your business.",
          ],
          color: "#0A2472",
        },
        {
          title: "Haptic Accessibility Symposium",
          company: "University of California, Irvine",
          company_url: "",
          logo_path: "uci.png",
          duration: "2002",
          location: "Irvine CA",
          descriptions: [
            "Symposium on the development and effect of haptic feedback in input devices and how it relates to websites and accessibility.",
          ],
          color: "#0A2472",
        },
        {
          title: "On-Air Personality",
          company: "Internet Solutions Radio",
          company_url: "",
          logo_path: "kmcd.png",
          duration: "1998 – 2000",
          location: "Fairfield, IA",
          descriptions: [
            "On-Air personality for a 2 hour weekly radio show where live questions were answered on air concerning the internet and computers.",
          ],
          color: "#0A2472",
        },
      ],
    },
    {
      title: "Education and Certifications",
      experiences: [
        {
          title: "Ongoing Education",
          company: "",
          company_url: "",
          logo_path: "education.svg",
          duration: "Current",
          location: "Costa Mesa, CA",
          descriptions: [
            "In addtion to my formal education, about 25% of every week is utilized for advanced training and education to stay current in this ever changing field.",
          ],
          color: "#0A2472",
        },
        {
          title: "Costa Mesa CERT Certified",
          company: "CERT",
          company_url: "",
          logo_path: "cert.png",
          duration: "2011 - Current",
          location: "Costa Mesa, CA",
          descriptions: ["Certified CERT Disaster Responder"],
          color: "#0A2472",
        },
        {
          title: "National Registry of EMTs",
          company: "NREMT",
          company_url: "",
          logo_path: "nremt.png",
          duration: "2011 - 2014",
          location: "USA",
          descriptions: ["Nationally registered EMT"],
          color: "#0A2472",
        },
        {
          title: "Orange County EMS Accreditation",
          company: "OCEMS",
          company_url: "",
          logo_path: "ocems.png",
          duration: "2011 - 2014",
          location: "Orange County, CA",
          descriptions: [
            "Special accreditation from OC EMS to practice in Orange County",
          ],
          color: "#0A2472",
        },
        {
          title: "Orange Coast College",
          company: "EMT Program",
          company_url: "",
          logo_path: "occ_logo.png",
          duration: "2011",
          location: "Costa Mesa, CA",
          descriptions: ["Perfect Score in training"],
          color: "#0A2472",
        },
        {
          title: "Orange Coast College",
          company: "Computer Science",
          company_url: "",
          logo_path: "occ_logo.png",
          duration: "1997",
          location: "Costa Mesa, CA",
          descriptions: ["Computer Science"],
          color: "#0A2472",
        },
      ],
    },
    {
      title: "Volunteer",
      experiences: [
        {
          title: "Costa Mesa CERT",
          company: "CERT",
          company_url: "https://costamesacert.org/",
          logo_path: "cert.png",
          duration: "2011 - Current",
          location: "Costa Mesa, CA",
          descriptions: [
            "Volunteer with the Community Emergency Response Team (CERT) in Costa Mesa.  Set up and maintained website and social media presence.  Attended and assisted with monthly and annual training events.  Served on the leadership/steering committee.",
          ],
          color: "#0A2472",
        },
        {
          title: "Technical Support",
          company: "Volunteer Clown Network of Orange County",
          company_url: "",
          logo_path: "blank.png",
          duration: "2014 - 2016",
          location: "Orange County, CA",
          descriptions: [
            "Provided technical support, set up & maintained website, and social media presence for the Volunteer Clown Network who visit sick children in hospitals.",
          ],
          color: "#0A2472",
        },
        {
          title: "Developer Program Member",
          company: "GitHub",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "Current",
          location: "Internet",
          descriptions: [
            "Actively contribute to a number of open source projects such as Qtile, Beets, Python, FoxDot, and more. These contributions include bug fixes, tests, feature requests and creating proper documentation for the project.",
          ],
          color: "#0A2472",
        },
        {
          title: "Blood/Platelet Donor",
          company: "American Red Cross",
          company_url: "https://redcrossblood.org/",
          logo_path: "redcross.png",
          duration: "1995-Current",
          location: "Red Cross Locations",
          descriptions: [
            "Active apheresis platelet donor as often as possible (about every 2 weeks).  I have donated hundreds of times and am among the top donors at my location.",
          ],
          color: "#0A2472",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "I have managed and worked on many IT and programming projects using a vast array of technologies. ",
  avatar_image_path: "projects_image.svg",
};

const reference = {
  title: "References",
  subtitle: "",
  description:
    "Over the years people and companies have been kind enough to put pen to paper and tell the world what they think of me.  Full reproductions, with contact info, available upon request.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Wholesale Medical Supplies",
      references: [
        {
          title: "",
          key: "MedSupplies",
          company: "",
          company_url: "",
          logo_path: "blank.png",
          duration: "",
          location: "Lake Forest, CA",
          descriptions: [
            "To Whom it May Concern,",
            "I am pleased to provide this letter of reference for Malachi Clark and Techglia. We have worked with Malachi and his team since 2002 and, during that time, it has been our experience that they demonstrate extreme competence in their work. They have always displayed a high degree of integrity, responsibility, and responsiveness to our needs.",
            "Malachi has been masterful at managing our existing aging accounting system to help keep our expenses low.  Despite our system being long past it’s expected lifespan, he kept has kept us intact and operational.  Thankfully, he is now helping us with our migration to our new cloud based accounting and inventory system.",
            "Techglia has helped us with budgeting and purchasing of new systems, maintenance and monitoring of our existing systems, our exchange servers and has been with us through moves to three different facilities.  During all, Malachi has been accessible, respectful, and an absolute pleasure. He is truly the kindest, calmest, most capable person I know.  To say he joy to have as part of our team would be an understatement.",
            "Working with Techglia has been a great benefit and alternative to us having a full time IT person on staff. Without reservation I am pleased to give him my enthusiastic endorsement. I am available to provide additional information if needed and can be reached at (949) ***-****.",
          ],
          color: "#0A2472",
        },
      ],
    },
    {
      title: "Business Partner and Entrepreneur",
      references: [
        {
          title: "",
          key: "BusPartner",
          company: "",
          company_url: "",
          logo_path: "blank.png",
          duration: "",
          location: "Aliso Viejo, CA",
          descriptions: [
            "To whom it may concern,",
            "I met Malachi Clark in 1997, and I have developed a close personal and professional relationship with him. As the owner of broadcast properties and as a web developer, I have worked with many IT professionals, and as far as I am concerned, Malachi is simply the best there is when it comes to everything from setting up a network to programming a complex app.",
            "In our 20-years-plus history, Malachi and I have partnered on several ventures, and I would happily do so again. Not only does he have unparalleled technical skills, but he is a great manager as well. That combination has been a big part of our successes together.",
            "But wait – there’s more. You can add “communicator” to Malachi’s skill set. Rare among technical people, he has the ability of making high-level concepts easy for a lay person to understand – so much so that we gave him his own live program on one of my radio stations. I have also seen his communication skills on display in myriad presentations to audiences large and small.",
            "I can confidently say that anyone who has projects that need superior technical skills coupled with a steady, deliberate, hand on the helm could do no better than Malachi Clark.",
          ],
          color: "#0A2472",
        },
      ],
    },
    {
      title: "Hand Sanitizer Manufacturer and Distributor",
      references: [
        {
          title: "",
          company: "",
          key: "HandSanitizer",
          company_url: "",
          logo_path: "blank.png",
          duration: "",
          location: "Lake Forest, CA",
          descriptions: [
            "After a long search and several IT vendor later, [our company] chose the team of Techglia, Inc and Malachi Clark in 2016 as our sole outsourced IT vendor.  Techglia did not only replace our existing in-house personnel that were previously handling [our] IT tasks but also outside IT vendors we consulted at different times.  Malachi and team not only significantly reduced our costs associated with with that department but literally took every IT headache away from [our company] and allowed us to focus on what we are good at.  We now have considerably fewer issues while we spend a fraction of the time and money we previously did dealing with IT.",
            "The Techglia team cleaned up all our technical operations, reduced our IT risks, upgraded our network and servers, increased stability and helped bring our new manufacturing equipment online.  Malachi guides us in a very proactive way by making suggestions to ensure business continuity and improve our existing efficiencies.",
            "As an international hand sanitizer company, when Covid hit the globe our company was inundated with orders.  Malachi and his team skillfully kept our entire infrastructure running smoothly.  Techglia’s prior work ensured we had ample capacity in our network services but more than that, Malachi personally developed custom software that imported the massive influx of orders directly into our ERP system enabling us to fulfil orders faster and error free.  That synergy of expert IT management and custom programming continues to help our company daily with new tasks as they arise.",
            "I can only say, with confidence and joy, that any business would profit from having Malachi and his team take over their IT and programming needs.  We are so happy to have found them and will use them for years to come.",
          ],
          color: "#0A2472",
        },
      ],
    },
    {
      title: "Citation Processing Center",
      references: [
        {
          title: "",
          key: "Citation",
          company: "",
          company_url: "",
          logo_path: "blank.png",
          duration: "",
          location: "Santa Ana, CA",
          descriptions: [
            "At [our company], we process citations for many city and county police departments throughout California. In this digital world, our IT systems are paramount to our success. Upon a recommendation we hired Techglia to audit and analyze our IT infrastructure left behind by our departed in-house IT staff.",
            "Techglia thoroughly and skillfully audited every server and system across a wide array of metrics. They investigated power, networking, physical and virtual servers, software services, application code, business processes and many other items. A detailed report was given to us along with several exhaustive explanations of the results.",
            "To be honest, many of the findings and recommendations were not what we wanted to hear, but they were what we needed to hear. Malachi politely and diplomatically informed us where our systems were deficient and what was needed both from IT and software to maintain our already excellent service levels.",
            "Ultimately, Malachi and his team gave us the clear roadmap we need to succeed well into the future and the peace of mind we needed. I wholeheartedly recommend Techglia to other organizations that need a thorough, honest look at their IT infrastructure as you will not be disappointed.",
          ],
          color: "#0A2472",
        },
      ],
    },
    {
      title: "Auto Body",
      references: [
        {
          title: "",
          key: "Citation",
          company: "",
          company_url: "",
          logo_path: "blank.png",
          duration: "",
          location: "Lake Forest, CA",
          descriptions: [
            "To Whom it May Concern,",
            "Malachi Clark of Techglia has been our IT vendor since 2014.  We hired him and his team to take care of our network and other technology needs, and while they are modest, he has always treated us with the courtesy, promptness, and respect of a large company.  Techglia is always available by phone, text or email to help us out.  Malachi always arrives when he says he will, professionally attired and with the required equipment to tackle whatever problem we have.  An auto body shop is a loud, dusty business but he is never hesitant to do what it takes to get us up and running again.",
            "Malachi has an amazing depth of knowledge and an easy-going personality; he is able to translate technical jargon into plain language to explain an issue and describe how he is going to correct it.  He is patient with answering questions, and never speaks to us condescendingly.  It is important to him  that we understand our technology so we can use it well.  By outsourcing our IT to Malachi on an hourly basis we are able to keep our IT costs low, he is conscientious and efficient in his use of time.",
            "I wholeheartedly recommend Malachi Clark and Techglia for any IT service needs your company may have - he is quick, friendly, and as expert as it gets!",
          ],
          color: "#0A2472",
        },
      ],
    },
    {
      title: "Stainless Steel Foundry",
      references: [
        {
          title: "",
          key: "Foundry",
          company: "",
          company_url: "",
          logo_path: "blank.png",
          duration: "",
          location: "South Gate, CA",
          descriptions: [
            "To Whom It May Concern:",
            "We have been doing business with Techglia, Inc. since 2004. They installed our Dell server and workstations. Techglia has also done all ongoing maintenance and trouble shooting on our system. We have been extremely satisfied with their expertise and response times to all requests we have made.",
            "Techglia also assisted us in setting up our web site and e-mail system. They do all updates and software installations for our anti-virus software.",
            "We are very pleased with their performance and availability to assist with any questions we have.",
          ],
          color: "#0A2472",
        },
      ],
    },
    {
      title: "Employee",
      references: [
        {
          title: "",
          key: "employee",
          company: "",
          company_url: "",
          logo_path: "blank.png",
          duration: "",
          location: "Costa Mesa, CA",
          descriptions: [
            "To Whom It May Concern",
            "I have worked closely with Malachi Clark for the past seven years while he has served as the President of Techglia, Inc.  He is active in all aspects of Techglia’s business from hiring, to planning and budgeting, to, of course, IT and programming.  I have been consistently impressed by Malachi’s skills and leadership, and I am confident that he would be successful anywhere.",
            "Malachi has always had a great personality for handling our customers, patiently listening and understanding their needs.  As Techglia has many clients, each with their own office and personnel, he deftly handles both the diplomacy of intra-office and inter-office politics with ease.  Because we are an outside IT partner for our clients, Malachi must manage the concerns, expectations and budgets of personnel, from the CEO to the intern, to facilitate success within the entire organization and I have seen him do just that on a daily basis.",
            "Malachi believes in mentoring and training through assisting all Techglia employees, taking his own time to ensure their success, both with immediate knowledge and long term progress.  His technical expertise is second to none and inspires the employees to greatness by lifting them up through opportunities.  That training mentality extends to our clients where Malachi regularly gives presentations on various topics, often explaining complex topics to people with almost no computer experience.",
            "Anyone would be lucky to have Malachi as their supervisor, as I have been.  He is a phenomenal leader, but more than that I have never worked for a more patient, caring and empathic person.",
          ],
          color: "#0A2472",
        },
      ],
    },
    {
      title: "Aerospace and Military Parts Manufacturer",
      references: [
        {
          title: "",
          key: "parts",
          company: "",
          company_url: "",
          logo_path: "blank.png",
          duration: "",
          location: "Brea, CA",
          descriptions: [
            "To Whom It May Concern:",
            "I am pleased to provide this letter of reference for Malachi Clark and Techglia. Since 2017 we have utilized Techglia for all our IT needs.  Malachi took over from our prior IT vendor after a disgruntled employee deleted data that could not be recovered.  Malachi came to our aid and, beyond expectations, recovered our data.",
            "During that stressful time, on the brink of losing our company, tensions were high.  Malachi kept a calm and patient demeanor, easing us back from the edge with poise and a respectful attitude.  He assured us it would all work out and it did.  We now utilize Techglia for all our IT needs and rest easy knowing that it is done right.",
            "Utilizing Techglia for our outsourced IT has been amazing.  Costs stay low, but we know that we always have the best IT expert in the industry one phone call away.  I can’t recommend Malachi and Techglia highly enough!",
          ],
          color: "#0A2472",
        },
      ],
    },
    {
      title: "Attorney - Negotiations",
      references: [
        {
          title: "",
          key: "negotiator",
          company: "",
          company_url: "",
          logo_path: "blank.png",
          duration: "",
          location: "Costa Mesa, CA",
          descriptions: [
            "To Whom This May Concern:",
            "This Letter of Recommendation is inscribed on behalf of Malachi Clark and Techglia.",
            "There was a time when our law practice was in desperate need of a dependable and reliable company like Techglia, and an IT expert like Malachi. Eventually Malachi was referred to us by an attorney on our floor who raved about him. We soon learned that Malachi and his team are highly-gifted individuals. Malachi is extremely professional; kind-hearted; and very personable. Malachi always arrives, and departs with an excellent attitude, regardless of how complicated our issues are.",
            "Malachi is a well-seasoned IT professional. He and his team of achievers are a winning combination of extensive knowledge and dependable expertise, which authenticates their collective abilities to be extremely proficient and up-to- speed with the latest and greatest technology concerning our infrastructure. In addition to their on-site service, their best asset is their ability to log in remotely after hours whenever we have issues; and we’ve had many, many issues.",
            "Our latest IT project was contracting with Techglia to rebuild our entire server room workstation, providing and constructing a custom housing center for our servers, modems, Wi-Fi, cabling, racks, power management and battery backups. Under separate attachment, I have included photographs which demonstrate their tremendous efforts. Needless to inscribe, we were overjoyed with the spectacular outcome. Everything is housed in one place, and neatly organized.",
            "Surprisingly, Malachi’s proposed budgets are always exceptionally reasonable; and believe me, I have compared his hardware prices on several occasions. Moreover, I’ve relentlessly text-messaged Malachi when we were down on several occasions, and he has always quickly replied. In reality, Malachi is our firm’s super-hero.",
            "If you are searching for dependability and reliability, and haven't [yet] had the pleasure or opportunity of finding someone like Malachi, I can personally assure you that, your first encounter with Malachi will etch an everlasting impression upon you, and you will come to have an infinite appreciation for such a well-balanced; detail-oriented, articulated individual like Malachi, who speaks in IT language.",
            "In the event that you wish to authenticate this Letter of Recommendation, I encourage to reach me by referencing my contact information [below].",
          ],
          color: "#0A2472",
        },
      ],
    },
    {
      title: "Sign Manufacturer",
      references: [
        {
          title: "",
          key: "signs",
          company: "",
          company_url: "",
          logo_path: "blank.png",
          duration: "",
          location: "Manhattan Beach, CA",
          descriptions: [
            "To Whom It May Concern,",
            "I have worked with Malachi Clark and Techglia, Inc. for over 14 years now. Techglia has been the sole provider of our IT services the entire time. I can't tell you how invaluable Malachi has been over the years. His wide ranging knowledge in regards to IT, IT security, information storage and back up and hardware procurement have help me grow my business through the years. Techglia, has upgraded computers, migrated our email to the cloud and even made house calls!",
            "My time is precious and to know that if I run into a problem with my system, that I can call Malachi and, at a moments notice, he will be able to help me, if not immediately then within a very reasonable time frame. This is priceless, not only to me personally but to my business. Malachi masterfully handles the IT for our company, and our stressful phone calls, with calm and gives us the piece of mind we need to continue on with the business at hand.",
            "We couldn't be happier with our 'on-call' IT support. Malachi and the whole Techglia team are superior in every way. I highly recommend Techglia to all companies who need an IT partner to keep them running smoothly.",
            "Please feel free to contact me if you have any specific questions in regards to Techglia or their reliable service.",
          ],
          color: "#0A2472",
        },
      ],
    },
    {
      title: "Dental Appliance Manufacturer",
      references: [
        {
          title: "",
          key: "dental",
          company: "",
          company_url: "",
          logo_path: "blank.png",
          duration: "",
          location: "Ceritos, CA",
          descriptions: [
            "To Whom It May Concern:",
            "In February of 2018, our IT provider quit and left us with a VMWare server and several guest operating systems for which we didn’t have the passwords. We could not get access to any of the servers.  Upon a recommendation I called Techglia and spoke with Malachi Clark.  He came to our business that day and reset the passwords on all the servers.  We were impressed then and years later we still are happy.",
            "As a next generation dental appliance manufacturer, we use the latest in 3D printing technology to provide for our customers and Malachi keeps us running.  He is always incredibly responsive and timely and patiently explains concepts (like server virtualization) in ways anyone can understand.",
            "We love working with Malachi.  The use of outsourced IT, gives us hourly pricing only when we need it, with expert knowledge to back it up.  We would gladly recommend any business to outsource their IT to Malachi and Techglia.",
          ],
          color: "#0A2472",
        },
      ],
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "headshot6.png",
    description: "I am available by phone, email, or letter.",
  },
  // blogSection: {
  //   title: "Blogs",
  //   subtitle:
  //     "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
  //   link: "https://ashutoshhathidara.wordpress.com",
  //   avatar_image_path: "blogs_image.svg",
  // },
  addressSection: {
    title: "Address",
    subtitle: "2973 Harbor Blvd, Suite 173, Costa Mesa, CA 92626",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "714-309-4944",
  },
  emailSection: {
    title: "Email",
    subtitle: "malachi@malachi.cc",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  reference,
  projectsHeader,
  contactPageData,
};
